import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import Particle from "../Particle";
import { AiOutlineRead } from "react-icons/ai";
import { Document, Page, pdfjs } from "react-pdf";
import SignatureEncryption from "../../Assets/Papers/Signature-Encryption.pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

// function Papers() {
//   const [width, setWidth] = useState(1200);
//   const papers = [
//     {
//         title: "Signature-Based Encryption",
//         authors: "Saurav Jaiswal",
//         //conference: "Conference/Journal Name, 2023",
//         abstract: "Brief description of the paper's content and findings...",
//         pdfLink: "/path/to/paper1.pdf",
//     }
//   ]

//   useEffect(() => {
//     setWidth(window.innerWidth);
//   }, []);

//   return (
//     <div>
//       <Container fluid className="papers-section">
//         <Particle />
//         <Row style={{ justifyContent: "center", position: "relative" }}>
//           <Button
//             variant="primary"
//             href={pdf}
//             target="_blank"
//             style={{ maxWidth: "250px" }}
//           >
//             <AiOutlineDownload />
//             &nbsp;Download CV
//           </Button>
//         </Row>

//         <Row className="papers">
//           <Document file={pdf} className="d-flex justify-content-center">
//             <Page pageNumber={1} scale={width > 786 ? 1.7 : 0.6} />
//           </Document>
//         </Row>

//         <Row style={{ justifyContent: "center", position: "relative" }}>
//           <Button
//             variant="primary"
//             href={pdf}
//             target="_blank"
//             style={{ maxWidth: "250px" }}
//           >
//             <AiOutlineDownload />
//             &nbsp;Download CV
//           </Button>
//         </Row>
//       </Container>
//     </div>
//   );
// }

function Papers() {
  const papers = [
    {
      title: "Signature Encryption using Blockchain",
      authors: "Saurav Jaiswal",
      Journal: "International Journal of Engineering and Advanced Technology (IJEAT), 2024",
      pdfLink: SignatureEncryption,
      link: "https://www.ijeat.org/published-in-year-2024/"
    },
    // Add more papers as needed
  ];

  return (
      <Container fluid className="project-section">
        <Particle />
        <Container>
          <h1 className="project-heading text-center mb-5">
            Research <strong className="purple">Papers </strong>
          </h1>
          <Row xs={1} md={2} className="g-4">
            {papers.map((paper, index) => (
              <Col key={index}>
                <Card className="h-100 bg-transparent" style={{ border: "1px solid rgba(200, 137, 230, 0.637)" }}>
                  <Card.Body className="d-flex flex-column">
                    <Card.Title className="text-white">{paper.title}</Card.Title>
                    <Card.Subtitle className="mb-2 text-muted">
                      {paper.authors}
                    </Card.Subtitle>
                    <Card.Text className="small text-muted mb-3">
                    <a 
                        href={paper.link} 
                        target="_blank" 
                        rel="noopener noreferrer"
                        style={{ color: 'inherit', textDecoration: 'none' }}
                      >
                        {paper.Journal}
                    </a>                    
                    </Card.Text>
                    <div className="mt-auto">
                      <a
                        href={paper.pdfLink}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="btn btn-primary btn-sm"
                      >
                        <AiOutlineRead className="me-2" />
                        Read Paper
                      </a>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </Container>
  );
}

export default Papers;